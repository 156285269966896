export default {
  en: {
    common: {
      item_01: "Request error",
      item_02: "System error",
      item_03: "Ok",
      item_04: "Cancel",
      item_05: "Loading...",
      item_06: "Can't find in database",
      item_07: "Email Address",
      item_08: "Enter email address",
      item_09: "Please enter a valid email",
      item_10: "A wronge email address",
      item_11: "Password",
      item_12: "Enter password",
      item_13: "Please enter a valid password",
      item_14: "Password length equal to or greater than 6 digits",
      item_15: "Back",
      item_16: "A verification email has been sent",
      item_17: "Please check and verify",
      item_18: "Equipment IMEI Number",
      item_19: "Enter IMEI",
      item_20: "Please enter a valid imei",
      item_21: "Search",
      item_22: "Total",
      item_23: "Used",
      item_24: "Balance",
      item_25: "days",
      item_26: "Region",
      item_27: "Buy",
      item_28: "Purchase successed",
      item_29: "Purchase failed",
      item_30: "Search area",
      item_31: "Failed to query device, please add device",
      item_32: "Orientaltele iot service platform",
      item_33: "Price",
      item_34: "verification code",
      item_35: "Enter code",
      item_36: "Please enter a valid code",
      item_37: "Verification code error",
      item_38: "Failed to get camera",

      item_39: "HKD",
      item_40: "CNY",
      item_41: "USD",
      item_42: "JPY",
      item_43: "KRW",
      item_44: "EUR",
      item_45: "RUB",
      item_46: "GBP",
    },
    register: {
      item_01: "SIGN UP",
      item_02: "Sign up",
      item_03: "Sign in",
      item_04: "Successed.Sign in now.",
    },
    find: {
      item_01: "RESET PASSWORD",
      item_02: "Confirm",
      item_03: "Sign in",
      item_04: "New Password",
      item_05: "Enter new password",
      item_06: "Confirm password",
      item_07: "Re-enter password",
      item_08: "Password does not match",
      item_09: "Successed.Sign in now.",
    },
    login: {
      item_01: "SIGN IN",
      item_02: "Sign in",
      item_03: "Sign up",
      item_04: "Forgot password",
    },
    home: {
      item_01: "Sign in/up",
      item_02: "devices",
      item_03: "Status",
      item_04: "Normal",
      item_05: "Abnormal",
      item_06: "Add device",
      item_07: "Data Packages",
      item_08: "Buy Plans",
      item_09: "change Password",
      item_10: "log out",
    },
    device: {
      item_01: "Device List",
      item_02: "Status",
      item_03: "Normal",
      item_04: "Abnormal",
      item_05: "select all",
      item_06: "close",
      item_07: "Delete",
    },
    record: {
      item_01: "Package details",
      item_02: "Details",
      item_03: "Expire Date",
      item_04: "Status",
      item_05: "Invalid",
      item_06: "Inactivated",
      item_07: "Activated",
      item_08: "Used",
      item_09: "Total",
    },
    order: {
      item_01: "Order History",
      item_02: "Total",
    },
  },
  zh: {
    common: {
      item_01: "请求错误",
      item_02: "系统错误",
      item_03: "确定",
      item_04: "取消",
      item_05: "请稍等...",
      item_06: "暂无数据",
      item_07: "邮箱账号",
      item_08: "请输入邮箱账号",
      item_09: "请输入有效邮箱",
      item_10: "邮箱格式错误",
      item_11: "密码",
      item_12: "请输入密码",
      item_13: "请输入有效密码",
      item_14: "密码必须大于或等于6位",
      item_15: "返回",
      item_16: "已发送验证电子邮件",
      item_17: "请检查并执行操作",
      item_18: "设备IMEI编号",
      item_19: "请输入设备IMEI编号",
      item_20: "请输入正确的IMEI",
      item_21: "查找",
      item_22: "总量",
      item_23: "使用",
      item_24: "剩余",
      item_25: "天",
      item_26: "区域",
      item_27: "购买",
      item_28: "购买成功",
      item_29: "购买失败",
      item_30: "搜索地区",
      item_31: "查询设备失败，请添加设备",
      item_32: "东讯 iot 服务平台",
      item_33: "价格",
      item_34: "验证码",
      item_35: "请输入验证码",
      item_36: "请输入有效验证码",
      item_37: "验证码错误",
      item_38: "获取摄像头失败",

      item_39: "港币",
      item_40: "人民币",
      item_41: "美元",
      item_42: "日元",
      item_43: "韩元",
      item_44: "欧元",
      item_45: "卢布",
      item_46: "英镑",
    },
    register: {
      item_01: "注册",
      item_02: "注册",
      item_03: "登录",
      item_04: "注册成功，前往登录",
    },
    find: {
      item_01: "密码重置",
      item_02: "提交",
      item_03: "登录",
      item_04: "新密码",
      item_05: "请输入新密码",
      item_06: "确认密码",
      item_07: "再次输入密码",
      item_08: "密码不一致",
      item_09: "修改密码成功，前往登录",
    },
    login: {
      item_01: "登录",
      item_02: "登录",
      item_03: "注册",
      item_04: "忘记密码",
    },
    home: {
      item_01: "登录/注册",
      item_02: "台设备",
      item_03: "状态",
      item_04: "正常",
      item_05: "异常",
      item_06: "添加设备",
      item_07: "套餐信息",
      item_08: "套餐购买",
      item_09: "修改密码",
      item_10: "注销",
    },
    device: {
      item_01: "设备列表",
      item_02: "状态",
      item_03: "正常",
      item_04: "异常",
      item_05: "全选",
      item_06: "关闭",
      item_07: "删除",
    },
    record: {
      item_01: "套餐列表",
      item_02: "详情",
      item_03: "有效期",
      item_04: "状态",
      item_05: "失效",
      item_06: "未激活",
      item_07: "已激活",
      item_08: "已用",
      item_09: "总量",
    },
    order: {
      item_01: "购买记录",
      item_02: "总额",
    },
  },
};
