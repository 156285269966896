import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/register",
    component: () => import("./views/register.vue"),
  },
  {
    path: "/find",
    component: () => import("./views/find.vue"),
  },
  {
    path: "/login",
    component: () => import("./views/login.vue"),
  },
  {
    path: "/quick",
    component: () => import("./views/quick.vue"),
  },
  {
    path: "/",
    component: () => import("./views/home.vue"),
  },
  {
    path: "/country",
    component: () => import("./views/country.vue"),
  },
  {
    path: "/device",
    component: () => import("./views/device.vue"),
  },
  {
    path: "/order",
    component: () => import("./views/order.vue"),
  },
  {
    path: "/record",
    component: () => import("./views/record.vue"),
  },
  {
    path: "/scan",
    component: () => import("./views/scan.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from) => {
  const { aplus_queue } = window;
  aplus_queue.push({
    action: "aplus.sendPV",
    arguments: [
      {
        is_auto: false,
      },
      {
        page_path: to.path,
      },
    ],
  });
  aplus_queue.push({
    action: "aplus.record",
    arguments: [
      "user_page",
      "CLK",
      { page_path: to.path, from_path: from.path },
    ],
  });
});

export default router;
