import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import axios from "axios";

import { createI18n } from "vue-i18n/index";
import I18n from "./i18n";
if (!window.sessionStorage["lang"]) window.sessionStorage["lang"] = "en";
const i18n = createI18n({
  locale: window.sessionStorage["lang"],
  messages: I18n,
});

import { IndexBar, IndexAnchor, Cell } from "vant";

const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$buryPoint = function (
  codeKind,
  argInfo,
  eventType = "CLK",
  action = "aplus.record"
) {
  const { aplus_queue } = window;
  if (
    !codeKind ||
    typeof codeKind != "string" ||
    typeof argInfo != "object" ||
    Object.prototype.toString.call(argInfo).toLowerCase() != "[object object]"
  )
    return;
  aplus_queue.push({
    action,
    arguments: [codeKind, eventType, argInfo],
  });
};

app
  .use(IndexBar)
  .use(IndexAnchor)
  .use(Cell)
  .use(Quasar, quasarUserOptions)
  .use(i18n)
  .use(router)
  .mount("#app");
