<template>
  <router-view id="page" />
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
#page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

<script>
import VConsole from "vconsole";
import axios from "axios";
export default {
  data() {
    return {
      httpProcess: false,
      debug: false,
      vConsole: null,
    };
  },
  beforeCreate() {
    (function (w, d, s, q, i) {
      w[q] = w[q] || [];
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s);
      j.async = true;
      j.id = "beacon-aplus";
      j.src = "https://d.alicdn.com/alilog/mlog/aplus/" + i + ".js";
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "aplus_queue", "203467608");

    const { aplus_queue } = window;
    aplus_queue.push({
      action: "aplus.setMetaInfo",
      arguments: ["appKey", "622ff2fe317aa87760990bd6"],
    });
    aplus_queue.push({
      action: "aplus.setMetaInfo",
      arguments: ["aplus-waiting", "MAN"],
    });
  },
  unmounted() {
    if (this.debug) this.vConsole.destroy();
  },
  created() {
    if (this.debug) this.vConsole = new VConsole({ theme: "dark" });
    this.getData();
  },
  mounted() {
    document.title = this.$t("common.item_32");
    // 禁止ios页面下拉(放行特定元素)
    document.querySelector("#app").addEventListener(
      "touchmove",
      function (e) {
        var ok = e.target.getAttribute("is-scroll");
        if (!ok) {
          var p = e.target.parentNode;
          if (p) {
            ok = p.getAttribute("is-scroll");
          }
        }

        if (!ok) e.preventDefault();
      },
      {
        passive: false,
      }
    );
    // 禁止ios双击放大
    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      {
        passive: false,
      }
    );
  },
  methods: {
    getData() {
      var self = this;
      if (self.httpProcess) return;
      self.httpProcess = true;
      axios({
        method: "post",
        url: "/api/get/lang",
      })
        .then((res) => {
          self.httpProcess = false;
          if (res.data.lang) {
            self.$i18n.locale = res.data.lang;
            window.sessionStorage["lang"] = res.data.lang;
          }
        })
        .catch(function () {
          self.httpProcess = false;
        });
    },
  },
};
</script>
